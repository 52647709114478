




















import { Component, Vue } from "vue-property-decorator";
import Board from "@/components/Board.vue";
import BoardControl from "@/components/BoardControl.vue";

@Component({
  components: {
    Board,
    BoardControl
  }
})
export default class Minesweeper extends Vue {
  beforeCreate() {
    this.$store.dispatch('setMinesweeperBoardSize', { width: 10, height: 10})
  }
}
