





























import { Component, Vue } from "vue-property-decorator";
import Cell from "@/components/Cell.vue";
import _, {DebouncedFunc} from "lodash";

@Component({
  components: {
    Cell
  }
})
export default class Board extends Vue {
  private cellUpdateCall: DebouncedFunc<() => void>;

  mounted() {
    this.cellUpdateCall = _.debounce(() => {
      this.$store.dispatch("updateProbability");
      this.$store.dispatch("setDebounce", false);
    }, 1500);

    this.$store.watch(
      state => state.minesweeper.debounce,
      debounce => {
        if (!debounce) {
          this.cellUpdateCall.cancel();
        }
      }
    );
  }

  get width() {
    return this.$store.state.minesweeper.cells[0].length;
  }

  get height() {
    return this.$store.state.minesweeper.cells.length;
  }

  cellUpdate() {
    console.log("Updating...")
    this.$store.dispatch("setDebounce", true);
    this.cellUpdateCall();
  }

  get loading() {
    return this.$store.state.minesweeper.apiLoading;
  }

  get debouceRunning() {
    return this.$store.state.minesweeper.debounce;
  }
}
